@font-face {
  font-family: "Roboto-Regular";
  src: url("/fonts/Roboto-Regular.ttf") format("truetype");
  font-weight: normal;
}
@font-face {
  font-family: "Roboto-Bold";
  src: url("/fonts/Roboto-Bold.ttf") format("truetype");
  font-weight: bold;
}
@font-face {
  font-family: "Roboto-Medium";
  src: url("/fonts/Roboto-Medium.ttf") format("truetype");
  font-weight: medium;
}
@font-face {
  font-family: "Roboto-Light";
  src: url("/fonts/Roboto-Light.ttf") format("truetype");
  font-weight: lighter;
}

@font-face {
  font-family: "SF Pro Display";
  src: url("/fonts/SF-Pro-Display-Light.otf");
}

html {
  overflow-y: scroll;
}

html, body, div#__next, div#root {
  height: 100%;
  margin: 0;
  background-color: #F2F2F2;
}

.rbc-overlay {
  font-family: 'SF Pro Display';
}
